import React from "react"
import ArticlesIndex from "../components/index/ArticlesIndex"
import Ingress from "../components/index/Ingress"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout isHome>
    <SEO title="Jens Almqvist - Mat idag och mat i framtiden" isHome />
    {/* <div className="small-container">
      <Ingress />
    </div> */}
    <div className="bg-secondary">
      <ArticlesIndex />
    </div>
  </Layout>
)

export default IndexPage
