import React from "react"
import ListArticles from "../articles/ListArticles"

export default function ArticlesIndex() {
  return (
    <div className="small-container">
      <ListArticles />
    </div>
  )
}
